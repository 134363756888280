<template>
  <div>
    <v-card
      class="mx-auto pb-4"
      :elevation="0"
      tile
      color="blue-grey darken-3"
      v-if="!$vuetify.breakpoint.lgAndUp"
    >
      <div class="align-center pt-3 d-flex justify-center">
        <v-avatar size="80" color="white">
          <v-avatar size="78" color="black">
            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
          </v-avatar>
        </v-avatar>
      </div>
      <div
        class="text-subtitle-1 mb-2 font-weight-bold white--text text-center"
      >
        Edwin Donaldo
      </div>
      <v-tabs grow>
        <v-tab>Ventas</v-tab>
        <v-tab>Contacto</v-tab>
        <v-tab>Saldo</v-tab>

        <v-tab-item :key="0">
          <SalesCustomer />
        </v-tab-item>
        <v-tab-item :key="1">
          <DetailCustomer />
        </v-tab-item>
        <v-tab-item :key="2">
          <BalanceCustomer />
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-container v-if="$vuetify.breakpoint.lgAndUp" class="pt-0 pb-0">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="8" xl="9">
          <v-card>
            <SalesCustomer />
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="4" xl="3">
          <v-card>
            <DetailCustomer />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DetailCustomer from "@/components/customers/detail/DetailCustomer";
import SalesCustomer from "@/components/customers/detail/SalesCustomer";
import BalanceCustomer from "@/components/customers/detail/BalanceCustomer";

export default {
  props: {
    uid: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  components: {
    DetailCustomer,
    SalesCustomer,
    BalanceCustomer
  }
};
</script>
