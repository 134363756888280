<template>
  <v-container>
    <v-card
      class="caption text-center d-flex align-center"
      height="300px"
      elevation="0"
    >
      <div>
        En esta sección podrás asignar crédito a tus clientes, espera pronto
        esta funcionalidad. :)
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
