<template>
  <v-container>
    <v-card class="ma-auto mb-2 mt-2" elevation="4" color="#385F73" dark
      ><div class="d-flex flex-no-wrap justify-space-between pa-3">
        <div class="text-center ml-2">
          <h4 class="text-h5">$ 1221</h4>
          <p class="caption ma-0">Media</p>
        </div>
        <v-divider vertical></v-divider>
        <div class="text-center">
          <h4 class="text-h5">32</h4>
          <p class="caption ma-0">Compras</p>
        </div>
        <v-divider vertical></v-divider>
        <div class="text-center mr-2">
          <h4 class="text-h5">23</h4>
          <p class="caption ma-0">Devoluciones</p>
        </div>
      </div>
    </v-card>
    <div class="overline pt-2 pl-4 grey--text">
      Ultimas ventas realizadas
    </div>
    <v-divider></v-divider>
    <v-list-item
      v-for="(n, index) in 3"
      :key="index + '-cart'"
      class="mb-2 blue-grey lighten-5"
    >
      <v-list-item-content class="pt-1 pb-1">
        <div class="d-flex justify-space-between align-center">
          <div>
            <v-list-item-title class="text-subtitle-2"
              >Miercoles 29 de Jul. 2020</v-list-item-title
            >
            <v-list-item-subtitle class="caption  font-weight-light black--text"
              ><v-icon>payment</v-icon> $130.00</v-list-item-subtitle
            >
          </div>
          <div class="ma-0 ml-2 caption text-center">
            <p class="ma-0 grey--text">Articulos</p>
            <p class="ma-0 font-weight-medium">12</p>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
    <div class="text-center pb-2">
      <v-btn text small>Ver mas</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
