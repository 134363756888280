<template>
  <v-container>
    <v-container class="text-center pt-0">
      <v-row class="justify-center mt-2">
        <v-btn fab small color="indigo" dark class="mr-2" href="tel:7291004185"
          ><v-icon size="20">phone</v-icon></v-btn
        >
        <v-btn
          fab
          small
          color="green"
          dark
          class="mr-2"
          href="https://api.whatsapp.com/send?phone=51955081075"
          ><v-icon size="20">chat</v-icon></v-btn
        >
        <v-btn
          fab
          small
          color="red"
          dark
          class="mr-2"
          href="mailto:help@ssencial.com"
          ><v-icon size="20">email</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Nombre"
          outlined
          dense
          hide-details
          value="Edwin Donaldo"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          value="7291004185"
          disabled
          label="Telefono"
          type="tel"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          value="7291004185"
          disabled
          label="Whatsapp"
          type="tel"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          value="edwin@ssencial.com"
          disabled
          type="email"
          label="Correo"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          value="Toluca Mexico."
          disabled
          rows="2"
          outlined
          label="Dirección"
          hide-details
        ></v-textarea>
      </v-col>
      <v-col cols="12 text-center">
        <v-btn color="blue" dark>Editar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      rating: 4
    };
  }
};
</script>

<style></style>
